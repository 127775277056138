import axios from 'axios';
import APIManager from './APIManager';
import { get, post } from './REST';
import moment from 'moment';
import querystring from 'querystring-es3';
import Cookies from 'universal-cookie';
import concatURL from '../helpers/concatURL';

export function fetchDashboard() {
  return function (dispatch) {
    // console.log("fetchDashboard function");
    // console.log('-----');
    get({}, '/loader/dashboard', 'Fetch dashboard')
      .then(response => {
        // console.log("LOAD_DASHBOARD_FULFILLED");
        // console.log(response);
        // console.log('-----');
        dispatch({
          type: 'LOAD_DASHBOARD_FULFILLED',
          payload: response.data,
        });
      })
      .catch(err => {
        dispatch({ type: 'LOAD_DASHBOARD_REJECTED', payload: err });
      });
  };
}

export function fetchCalendar(params) {
  //month, first of month
  //week, first of week,
  //payperiod, first day, length
  //overtime, first day, length

  // let params2 = {
  //   "scope" : ["month", "week", "payperiod", "overtime"],
  //   "seldate" : 1488571440
  // };

  return function (dispatch) {
    dispatch({ type: 'UPDATE_VIEW_PENDING', payload: params });
    let body = {
      calendar: 'default',
      seldate: Math.floor(params.seldate / 1000),
      scope: params.scope,
    };
    return get(body, '/calendar/dutyroster', 'Fetch calendar')
      .then(response => {
        // console.log("LOAD_CALENDAR_FULFILLED");
        // console.log(response);
        // console.log('-----');
        dispatch({
          type: 'LOAD_CALENDAR_FULFILLED',
          payload: response.data,
        });
        dispatch({ type: 'UPDATE_VIEW_FULFILLED', payload: params });
        dispatch({ type: 'STORE_PRIVS', payload: response.data.priv });
        dispatch({
          type: 'STORE_DUTYROSTER_CONFIG',
          payload: response.data.config,
        });
      })
      .catch(function (err) {
        console.log('LOAD_CALENDAR_REJECTED');
        console.log(err);
        console.log('-----');
        dispatch({ type: 'LOAD_CALENDAR_REJECTED', payload: err });
      });
  };
}

export function updateView(params) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_VIEW_FULFILLED', payload: params });
  };
}

export function fetchDay(date) {
  const year = +date.year;
  const month = +date.month - 1;
  const day = +date.day;
  const epoch = new Date(year, month, day).getTime() / 1000;

  return function (dispatch) {
    // console.log("fetchDay function");
    // console.log('-----');
    let url = '/calendar/dutyroster/scope/day/seldate/' + epoch;
    return get({}, url, 'Fetch individual day')
      .then(response => {
        // console.log("LOAD_DAY_FULFILLED");
        // console.log(response);
        // console.log('-----');
        dispatch({
          type: 'LOAD_DAY_FULFILLED',
          payload: response.data,
        });
        dispatch({ type: 'STORE_PRIVS', payload: response.data.priv });
        dispatch({
          type: 'STORE_DUTYROSTER_CONFIG',
          payload: response.data.config,
        });
      })
      .catch(function (err) {
        console.log('LOAD_DAY_REJECTED');
        console.log(err);
        console.log('-----');
        dispatch({ type: 'LOAD_DAY_REJECTED', payload: err });
      });
  };
}

export function fetchCallouts(date) {
  let startDate = null;
  let endDate = null;

  if (date) {
    //TODO: handle params
  } else {
    startDate = moment().add(-8, 'w').format('YYYY-MM-DD');
    endDate = moment().add(8, 'w').format('YYYY-MM-DD');
  }

  let body = {
    start: startDate,
    end: endDate,
    type: 'all',
  };

  return function (dispatch) {
    return get(body, '/communications/callouts', 'Fetch callouts')
      .then(response => {
        // console.log("LOAD_CALLOUTS_FULFILLED");
        // console.log(response);
        // console.log('-----');
        dispatch({
          type: 'LOAD_CALLOUTS_FULFILLED',
          payload: response.data,
        });
      })
      .catch(function (err) {
        console.log('LOAD_CALLOUTS_REJECTED');
        console.log(err);
        console.log('-----');
        dispatch({ type: 'LOAD_CALLOUTS_REJECTED', payload: err });
      });
  };
}

export function fetchNotifications(setPollTimerRunning) {

  let body = {
    type: 'all',
  };

  return function (dispatch) {
    return get(body, '/communications/notifications', 'Fetch notifications')
      .then(response => {
        if (response.status && response.status >= 400) {
          console.log('LOAD_NOTIFICATIONS_REJECTED');
          setPollTimerRunning(false);
          dispatch({ type: 'LOAD_NOTIFICATIONS_REJECTED', payload: response.data.errmsg });
        } else if (response.data && response.data.status == 0) {
          console.log('Notifications are disabled');
          setPollTimerRunning(false);
        } else {
          setPollTimerRunning(true);
          console.log("LOAD_NOTIFICATIONS_FULFILLED");
          console.log(response.data);
          console.log('-----');
          dispatch({
            type: 'LOAD_NOTIFICATIONS_FULFILLED',
            payload: response.data,
          });
        }
      })
      .catch(function (err) {
        setPollTimerRunning(false);
        console.log('LOAD_NOTIFICATIONS_REJECTED');
        console.log(err);
        console.log('-----');
        //dispatch({ type: 'LOAD_NOTIFICATIONS_REJECTED', payload: err });
      });
  };
}

export function fetchExchangeRequests() {
  // let startDate = null;
  // let endDate = null;
  //
  // if(date) {
  //   //TODO: handle params for start/end date or use default
  // } else {
  //   startDate = moment().add(-8, 'w').format('YYYY-MM-DD');
  //   endDate = moment().add(8, 'w').format('YYYY-MM-DD');
  // }

  let body = {
    limit: 10,
    filter: 'dashboard',
  };

  return function (dispatch) {
    // console.log("fetchExchangeRequests function");
    // console.log('-----');
    return get(body, '/schedule/exchange_requests', 'Fetch exchange requests')
      .then(response => {
        // console.log("LOAD_EXCHANGES_FULFILLED");
        // console.log(response);
        // console.log('-----');
        dispatch({
          type: 'LOAD_EXCHANGES_FULFILLED',
          payload: response.data,
        });
        dispatch({
          type: 'STORE_PRIVS',
          payload: { exchanges: response.data.priv },
        });
      })
      .catch(function (err) {
        console.log('LOAD_EXCHANGES_REJECTED');
        console.log(err);
        console.log('-----');
        dispatch({ type: 'LOAD_EXCHANGES_REJECTED', payload: err });
      });
  };
}

export function login(params, query_params = '') {
  return function (dispatch) {
    let url = concatURL('/auth/login' + query_params);
    const build_commit_date = process.env.REACT_APP_LAST_COMMIT_DATE;
    const cookies = new Cookies();
    const push_notification_key = cookies.get('pushNotificationKey');
    const new_params = Object.assign({},params, {push_notification_key: push_notification_key}, {ui_version: 5.1, build_commit_date: build_commit_date});

    return axios
      .request({
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        data: querystring.stringify(new_params),
      })
      .then(response => {
        APIManager.setKey(response.data.apikey);
          const cookies = new Cookies();
          const weekInSeconds = 604800;
          //Set the cookie to expire in 2 weeks
          cookies.set('ndAPIKey', response.data.apikey, {
            path: '/',
            maxAge: weekInSeconds * 2,
          });
          cookies.set('ndUserType', response.data.user_type, {
            path: '/',
            maxAge: weekInSeconds * 2,
          });
	      return response;
      })
      .then(response => {
        // console.log('response from logging in:');
        // console.log(response);
        dispatch({ type: 'LOGIN_FULFILLED', payload: response.data });
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.log('Login failed:');
        console.log(err);
        dispatch({ type: 'LOGIN_REJECTED', payload: err });
        throw err;
      });
  };
}

// Force logout by clearing the APIKey cookie
export function clearApiKey() {
  return function (dispatch) {
	  APIManager.setKey('');
	  const cookies = new Cookies();
	  cookies.remove('ndAPIKey');
	  cookies.remove('ndUserType');
	  const pl = {
		  apikey: '',
		  user_type: 'normal',
	  }
	  dispatch({ type: 'LOGOUT_FULFILLED', payload: pl });
	  return Promise.resolve(pl);
  };
}

export function cookieAuth(key, userType) {
  return function (dispatch) {
    APIManager.setKey(key);
    const pl = {
      apikey: key,
      user_type: userType,
    };
    dispatch({ type: 'LOGIN_FULFILLED', payload: pl });
    return Promise.resolve(pl);
  };
}

export function logout() {
  return function (dispatch) {
    // console.log("Logging out...");
    const cookies = new Cookies();
    const push_notification_key = cookies.get('pushNotificationKey');
    const post_args = {'push_notification_key': push_notification_key};

    return post(post_args, '/auth/logout', 'Log out')
      .then(response => {
        // console.log('response from logging out:');
        // console.log(response);
        cookies.remove('ndAPIKey');
        cookies.remove('ndUserType');
        cookies.remove('pushNotificationKey');
        dispatch({ type: 'LOGOUT_FULFILLED', payload: response.data });
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.log('Logout failed:');
        console.log(err);
        //dispatch({ type: 'LOGOUT_REJECTED', payload: err.data });
        //return err;
      });
  };
}

export function submitPasswordReset(params) {
  return function () {
    console.log('Sending password reset...');
    let url = concatURL('/auth/reset');
    return axios
      .request({
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        data: querystring.stringify(params),
        validateStatus: false,
      })
      .then(response => {
        // console.log('Response from resetting:');
        // console.log(response);
        return response.data;
      })
      .catch(err => {
        console.log('Reset failed:');
        console.log(err);
        return err;
      });
  };
}

export function submitChangePassword(params) {
  return function (dispatch) {
    // console.log("Changing password...");
    return post(params, '/auth/change_pw', 'Change password')
      .then(response => {
        // console.log('Response from changing password:');
        // console.log(response);
        dispatch({
          type: 'CHANGE_PASSWORD_FULFILLED',
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch(err => {
        console.log('Change pw failed:');
        console.log(err);
        return err;
      });
  };
}

export function fetchUserPageConfig() {
  return function (dispatch) {
    return get({}, '/loader/header', 'Fetch user page config').then(response => {
      dispatch({
        type: 'STORE_USERPAGE_CONFIG',
        payload: response.data,
      });
      return Promise.resolve(response.data);
    });
  };
}

//stores a given tabs object. This will be an object constructed by the Admin
// Tabs component, representing tabs mapping labels to callback functions to
// link the global header to the admin content
export function storeHeaderTabs(tabs) {
  return function (dispatch) {
    dispatch({ type: 'STORE_HEADER_TABS', payload: tabs });
  };
}

export function pushNotification(notif) {
  return function (dispatch) {
    dispatch(notif);
  };
}

export function reloadJsonContent() {
  return async function (dispatch) {
    dispatch({ type: 'RELOAD_JSON_CONTENT_PENDING' });
  };
}

export function fulfillJsonContent() {
  return async function (dispatch) {
    dispatch({ type: 'RELOAD_JSON_CONTENT_FULFILLED' });
  };
}
